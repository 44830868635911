

import UserService from '@/shared/services/user.service';
import UserTableService from '@/shared/services/usertable.service';
import { defineComponent, toRaw } from 'vue';
import { useToast } from 'vue-toast-notification';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Swal from 'sweetalert2';
import ResidentialView from './ResidentialView.vue';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import moment from 'moment';

//Number format
import { VueNumberFormat } from 'vue-number-format';
import RealtorDashboard from './RealtorDashboard.vue';
import InspectorDashboard from '@/components/InspectorDashboard.vue';
import Primary from './Tables/Primary.vue';
import TitleCompanyTables from '@/components/admin/Tables/TitleCompanyTables.vue';

export default defineComponent({
    data() {
        return {
            videoTutorial3: null,
            videoTutorial2: null,
            videoTutorial1: null,
            $toast: useToast(),
            userRole: null,
            userSession: null,
            tableUpdated: false,
            _window: null,
            listRoles: [],
            listUsers: [],
            listStates: [],
            listCountries: [],
            listZipCodes: [],
            listSections: [],
            loaderConfig: {
                isLoading: true,
                fullPage: true
            },
            paramsTable: null,
            params: {
                page: 1,
                pageSize: 10,
                pages: 0,
                totalRows: 5,
                usertype: "",
                state: "",
                country: "",
                zipcode: "",
                section: "",
                from: null,
                to: null
            },
            supportMessage: {
                iduser: null,
                message: null
            },
            configDatePicker: {
                date: null
            },
            tables: [],
            items: [
                { id: 1, name: 'Elemento 1' },
                { id: 2, name: 'Elemento 2' },
                { id: 3, name: 'Elemento 3' },
                { id: 4, name: 'Elemento 4' }
            ],
            searchTerm: ''
        }
    },
    components: {
        Loading,
        ResidentialView,
        VPagination,
        RealtorDashboard,
        InspectorDashboard,
        Primary,
        TitleCompanyTables,
        VueNumberFormat //Component NumberFormat
        
    },
    created() {
        this.init();

    },
    methods: {
        init: function () {
            this.getSession()

            this._window = window

            if (this.userSession.role == 'Realtor') {
                this.$router.push({
                    path: "/admin/panel/realtordashboard"
                })
            }

            this.loadRoles()
            this.getUsers()

            if (this.userSession.role == 'MLO' || this.userSession.role == 'Inspector' || this.userSession.role == 'Title Company') {
                this.getTables()
            }

            this.getCountries()
            this.getStates()
            this.getZipCodes()
        },
        submitReport: async function(event) {
            this.loaderConfig.isLoading = true
            event.target.disabled = true;
            let params: any = { ...this.supportMessage, iduser: this.userSession.iduser }

            const response = (await UserService.registerSupportMessage(params)).data

            if (!response.error) {
                this.supportMessage.iduser = null;
                this.supportMessage.message = null;

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500,
                });

                let w: any = window;
                w.modalclose("#exampleModalSupport");
            }
            else {
                this.$toast.error("Error: " + response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false
            event.target.disabled = false;
        },
        formatDate: (date: any) => {
            return date ? moment(date[0]).format("MM/DD/YYYY") + " to " + moment(date[1]).format("MM/DD/YYYY") : null;
        },
        submit: function () {
            console.log(toRaw(this.tables))

        },
        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userRole = session.role ? session.role : session.tipo_rol
                this.userSession = session
                console.log(this.userSession)
            } else {
                UserService.logout("Session expired!")
            }
        },
        getUsers: async function () {
            this.loaderConfig.isLoading = true
            let params: any = { ...this.params }
            params.section = isNaN(parseInt(this.params.section.replace("Seccion", ""))) ? "" : parseInt(this.params.section.replace("Seccion", ""));

            if (this.configDatePicker.date) {
                params.from = moment(this.configDatePicker.date[0]).format("YYYY-MM-DD");
                params.to = moment(this.configDatePicker.date[1]).format("YYYY-MM-DD");
            }

            const response = (await UserService.getUsers(params)).data

            if (!response.error) {
                this.params.page = toRaw(response.data).page;
                this.params.pages = toRaw(response.data).pages;
                this.params.pageSize = toRaw(response.data).pageSize;
                this.params.totalRows = toRaw(response.data).totalRows;
                let list: any = toRaw(response.data.results);
                this.listUsers = list.map(a => { return { ...a, createdOn: moment(a.createDate).format("MM/DD/YYYY") } })
            }
            else {
                this.$toast.error("Error loading users", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false
        },
        getTables: async function (user: any = null, typetable: any = null, pass = false) {
            this.loaderConfig.isLoading = true
            let tabletype: any = 0

            let ctables: any[] = [];

            if (this.userRole == "MLO") {
                if (this.$route.query.table) {
                    tabletype = (`${this.userSession.role}_${this.$route.query.table}`).toLowerCase()
                }
                else {
                    tabletype = this.userSession.role.toLowerCase() + "_primary"
                }
            }
            else {
                if (this.userRole) {
                    tabletype = `${this.userRole.replaceAll(" ", "").toLowerCase()}_table`
                }
            }

            let q = {
                user: this.userSession.id_user ? this.userSession.id_user : this.userSession.iduser,
                tabletype: tabletype
            }

            if (user != null) {
                q.user = user
            }

            if (typetable != null) {
                q.tabletype = typetable
            }

            const response = (await UserTableService.getTables(q)).data

            if (!response.error) {
                this.paramsTable = response.data ? response.data[0] : null

                if (!this.paramsTable && !pass) {
                    this.getTables();
                }

                if (this.paramsTable) {
                    let tabledata = JSON.parse(this.paramsTable.tabledata);

                    if (tabledata && tabledata.length > 0) {
                        tabledata.forEach((c: any) => {
                            if (c.convert) {
                                let listdata: any = []

                                c.data.forEach((v: any) => {
                                    let j: any = {}

                                    v.map((e: any) => {
                                        let o: any = {}
                                        e.values.forEach((w: any) => {
                                            o[`${e.name}_${w.name}`] = w.value
                                        })

                                        return o
                                    }).forEach((p: any) => {
                                        for (let f in p) {
                                            j[f] = p[f]
                                        }
                                    })

                                    v = [j]
                                    listdata.push(j)
                                })

                                c.data = listdata
                                ctables.push(c)
                            }

                        })

                        this.tables = ctables
                        console.log(this.tables);

                        if (response.message.outdated && !this.tableUpdated) {
                            this.tableUpdated = true;
                            this.submitTables([], null, false);
                            this.getTables();
                        }
                    }
                    else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'You dont have tables',
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }

                }
            }
            else {
                this.$toast.error("Error loading tables", { position: 'top-right' })
                console.log(response.message)

            }

            this.loaderConfig.isLoading = false

            return ctables;
        },
        submitTables: async function (extraTables: any = [], type: any = null, message: boolean = true) {

            let tabletype: any = 0

            if (this.userRole == "MLO") {
                if (this.$route.query.table) {
                    tabletype = (`${this.userSession.role}_${this.$route.query.table}`).toLowerCase()
                }
                else {
                    tabletype = this.userSession.role.toLowerCase() + "_primary"
                }
            }
            else {
                if (this.userRole) {
                    tabletype = `${this.userRole.replaceAll(" ", "").toLowerCase()}_table`
                }
            }

            if (type) {
                tabletype = type;
            }

            if (extraTables.length > 0) {
                this.tables = extraTables;
            }

            this.paramsTable.tabletype = tabletype
            this.paramsTable.iduser = this.userSession.id_user ? this.userSession.id_user : this.userSession.iduser
            this.paramsTable.tabledata = JSON.stringify(toRaw(this.tables));

            const response = await UserTableService.register(toRaw(this.paramsTable));

            if (!response.data.error) {
                console.log(response.data)

                // this.$toast.success("Tables saved!", { position: 'top-right' })
                if (message) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Data saved successfully',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }

            }
            else {
                this.$toast.error("Error!", { position: 'top-right' })
                console.log(response.data.message)
            }
        },
        loadRoles: async function () {
            this.loaderConfig.isLoading = true
            const response = await UserService.getRoles()
            let resdata = response.data

            if (!resdata.error) {
                let list: any = toRaw(resdata.data)
                this.listRoles = list
                this.listRoles.forEach((role, index) => {
                    role.abb = role.name.toLowerCase().replaceAll(" ", "")

                    if (role.name == "MLO") {
                        role.name = "MORTGAGE LOAN ORIGINATOR"
                    }

                    role.name = role.name.toUpperCase()
                })

            }
            else {
                this.$toast.error("Error loading roles", { position: 'top-right' })
                console.log(resdata.message)
            }

            this.loaderConfig.isLoading = false
        },
        getStates: async function () {
            this.loaderConfig.isLoading = true
            const response = (await UserService.getZoneStates()).data

            if (!response.error) {
                let list: any = toRaw(response.data)
                this.listStates = list
            }
            else {
                this.$toast.error("Error loading states", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false
        },
        getCountries: async function () {
            this.loaderConfig.isLoading = true;
            let state: any = this.params.state;
            let param = state == 0 ? state.toString() : state;
            const response = (await UserService.getCounty(param)).data

            if (!response.error) {
                let list: any = toRaw(response.data)
                if (list.length > 0) {
                    this.listCountries = list.filter((a: any) => a.length > 0);
                    this.params.country = "";
                    this.getCountySections();
                };
            }
            else {
                this.$toast.error("Error loading countries", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false
        },
        getCountySections: async function () {
            this.loaderConfig.isLoading = true;
            const response = (await UserService.getCountySections(this.params)).data

            if (!response.error) {
                this.listSections = toRaw(response.data);
                this.params.section = "";
            }
            else {
                this.$toast.error("Error loading countries", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false
        },
        getZipCodes: async function () {
            this.loaderConfig.isLoading = true
            const response = (await UserService.getUserZipCodes()).data

            if (!response.error) {
                let list: any = toRaw(response.data)
                // this.listZipCodes = list.filter((a: any) => a != 0)
            }
            else {
                this.$toast.error("Error loading zipcodes", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false
        },
        openModal: function (modalname: string, option: any, video: any = 1) {
            let _window: any = window;
            let bootstrap = _window.bootstrap;

            if (video == 1) {
                this.videoTutorial1 = option;
            }
            if (video == 2) {
                this.videoTutorial2 = option;
            }
            if (video == 3) {
                this.videoTutorial3 = option;
            }

            const modal = new bootstrap.Modal('#' + modalname)
            modal.show();

            const modalopen = document.getElementById(modalname)
            modalopen.addEventListener('hidden.bs.modal', event => {
                this.videoTutorial1 = null;
                this.videoTutorial2 = null;
                this.videoTutorial3 = null;
            })
        },
        loadZipCodes: function () {
            this.listZipCodes = this.listSections.find((a: any) => this.params.section == a.name).zips.map((c: any) => c.zipcode);
        },
        getSections() {
            console.log(this.listSections);
        },
        changeStatus: async function (user) {
            this.loaderConfig.isLoading = true;
            const params = {
                id: user.iduser,
                active: user.active
            }
            const response = (await UserService.updateStatus(params)).data

            if (!response.error) {
                this.getUsers();

                if (params.active) {
                    this.$toast.success("User enabled", { position: 'top-right' })
                }
                else {
                    this.$toast.error("User disabled", { position: 'top-right' })
                }
            }
            else {
                this.$toast.error("Error", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false
        }
    }
})


